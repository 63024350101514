import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { FaGithub } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ENV } from '../config'
import { db, DeleteField, firebaseAppAuth, githubProvider } from '../firebase'
import { Avatar } from '../styledComponents'


function Settings({authUser, user, setUser}) {
  
  const unlinkGithubAccount = async () => {
    await firebaseAppAuth.currentUser.unlink(githubProvider.providerId)
    await db.doc(`/users/${user.uid}`).update({
        githubToken: DeleteField(),
        githubProfile: DeleteField()
      })
    
    await alert("account unlinked!")

    const newUser = Object.assign({},user)
    delete newUser.githubProfile
    delete newUser.githubToken
    setUser(newUser)    
  }
    
  const debug = () => {
      console.log({authUser})
      console.log({user})
    debugger
  }

  return (
    <Container>
      <Link to="/app">back</Link>

      <div>Settings</div>
      { typeof user?.githubProfile === "object" && 
        <div>
          <p>Github Account: <a target="_blank" rel="noopener noreferrer" href={user?.githubProfile?.html_url}>{user?.githubProfile?.login}</a> </p>
          <GithubAvatar src={user?.githubProfile?.avatar_url} alt="Avatar" />

          <Button onClick={ () => unlinkGithubAccount() } variant="dark">
            <FaGithub />&nbsp; Unlink Github
          </Button>
      </div>
      }

      { ENV === 'dev' && 
        <Button onClick={ () => debug() } >
          &nbsp; debug
        </Button>
      }

    </Container>
  )
}

const GithubAvatar = styled(Avatar)`
  margin-right: 10px;
  margin-bottom: 10px
`

export default Settings