/* eslint-disable react-hooks/exhaustive-deps */
import {v4 as uuid} from 'uuid';

import { useState, useCallback, useRef } from 'react'
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

import { db, FirebaseTimestamp }from '../../firebase'

export default function useEmailTemplates(user, selectedProfile, keyword, location){
  
  const defaultTemplate = {
    id: "",
    title: "default template",
    createdAt: null,
    updatedAt: null,
    subject: "Use this template to send email to your prospects",
    body: EditorState.createEmpty(),
    owner: user.email
  }

  const renderTextContent = (content) => {

    const name = selectedProfile.name || "";
    const fname = name.split(" ")[0] 

    content = content?.replace( /\{name\}/g  , name || "{name}");
    content = content?.replace( /\{fname\}/g  , fname || "{fname}" );
    content = content?.replace( /\{keyword\}/g  , keyword || "{keyword}");
    content = content?.replace( /\{location\}/g  , location || "{location}");

    return content

  }

  const renderEditorStateContent = (content) => {
    if( !(content instanceof EditorState) ){
      throw new Error("renderEditorStateContent only works on EditorState objects")
    }

    let result = convertTemplateToJson(content)
    result = renderTextContent(result)
    return restoreTemplateBody(result)
  }

  function convertTemplateToJson(template){
    if( template instanceof EditorState ){
      template = convertToRaw(template.getCurrentContent());
    } 
    if( template.body instanceof EditorState ){
      template.body = convertToRaw(template.body.getCurrentContent());
    } 
    return JSON.stringify(template)
  }

  function restoreTemplate(template){
    if(typeof(template) === 'string'){
      template = JSON.parse(template)
    }
    if(typeof(template.body) === 'string'){
      template.body = JSON.parse(template.body)
    }
    template.body = EditorState.createWithContent(convertFromRaw(template.body));
    return template
  }

  function restoreTemplateBody(body){
    body = JSON.parse(body)
    body = EditorState.createWithContent(convertFromRaw(body));
    return body
  }


  const getSelectedTemplateFromLocalstorage = () => {
    const localTemplate = localStorage.getItem("selectedTemplate");
    if(localTemplate){
      console.log("$$$$$$ localTemplate", localTemplate)
      return restoreTemplate(localTemplate)
    }
    console.log("$$$$$$ no localTemplate")
    return defaultTemplate
  }
  
  const [templates, _setTemplates] = useState([]);
  const [selectedTemplate, _setSelectedTemplate] = useState( () => getSelectedTemplateFromLocalstorage() )

  const setSubject = (subject) => {
    _setSelectedTemplate({...selectedTemplate, subject})
  }

  const setTitle = (title) => {
    _setSelectedTemplate({...selectedTemplate, title})
  }
  
  const setTemplateEditorState = (editorState) => {
    _setSelectedTemplate({...selectedTemplate, body: editorState})
  }

  const templateEditorRef = useRef(null);



  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getTemplates = useCallback(async ()=>{
    setLoading(true)
    console.log(`user.id ${user.uid}`)

    const snapshot = await db
      .collection(`users/${user.uid}/emailTemplates`)
        .get()
      .then( templates => { return templates })
      .catch( err =>  setError("getTemplates Error: ", err) )
      .finally( () => setLoading(false) )

    return snapshot.docs.map(doc => {
      const templateData = doc.data()
      return restoreTemplate(templateData)
    })

  },[])

  const saveSelectedTemplateToLocalstorage = (template) => {
    localStorage.setItem("selectedTemplate", convertTemplateToJson({...template}))
  }

  const setSelectedTemplate = async (newTemplate) => {
    console.log("handling selected template change to", newTemplate.title)
    console.log("setSelectedTemplate, ", newTemplate)
    const tempTemplate = Object.assign({}, newTemplate)
    tempTemplate.body = EditorState.set(newTemplate.body, {})
    saveSelectedTemplateToLocalstorage(tempTemplate)
    _setSelectedTemplate({...tempTemplate})
  }

  const refreshTemplates = async ()=>{
    console.log('%%%% refreshing templates')
    try{
      const templates = await getTemplates()
      console.log('$$$ setting the templates', templates)
      _setTemplates(templates)
      // refreshSelectedTemplate(templates)
    } catch(err){
      console.log('%%%% error refreshing templates', err)
      setError("refreshTEmplates Error: ", err.toString())
    }
    return templates
  }
  
  const storeTemplate = async (template, id) =>{
    template.updatedAt = FirebaseTimestamp()
    const firebaseRef = db.collection(`users/${user.uid}/emailTemplates`);
    
    setLoading(true)
    if(!id){  
      template.id = uuid()
      template.createdAt = template.updatedAt
      console.log("no id, setting new", template.id)
      await firebaseRef.doc(template.id)
        .set(template)
        .catch( err => setError(err) )
        .finally( setLoading(false) )
      } else{
        console.log("id, updating: ", template.id)
        await firebaseRef.doc(template.id)
        .update(template)
        .catch( err => setError(err) )
        .finally(  setLoading(false) )
      }
      return template.id
    }

  const saveTemplate = async () => {
    if (selectedTemplate.title.length === 0){
      return setError("Error: Templates must have a title.")
    } 

    const bodyAsJson = JSON.stringify(convertToRaw(selectedTemplate.body.getCurrentContent()));
    const currentTemplate = templates.find( t =>  t.title === selectedTemplate.title  )
    const id = currentTemplate ? currentTemplate.id : null;
    const newTemplateWithJsonBody = {...selectedTemplate, body: bodyAsJson}
    
    const newSelectedTemplate = Object.assign({}, selectedTemplate)

    newSelectedTemplate.id = await storeTemplate( newTemplateWithJsonBody, id)
    
    await refreshTemplates()

    setSelectedTemplate(newSelectedTemplate)
  }

  const deleteTemplate = async () => {
    console.log("deleting template: ", selectedTemplate.id)
    setLoading(true)
    await db.doc(`users/${user.uid}/emailTemplates/${selectedTemplate.id}`)
      .delete()
      .catch(err => {
        console.log("error deleting", err)
        setError(err)
      })
      .finally( setLoading(false) )

      const newTemplates = templates.filter( t => t.id !== selectedTemplate.id)

      if(newTemplates.length === 0){
        newTemplates[0] = defaultTemplate
      }

      _setTemplates(newTemplates)
      setSelectedTemplate(newTemplates[0])
    }

    return {
      useTemplatesError: error, 
      useTemplatesLoading: loading,
      setTitle,
      setSubject,
      selectedTemplate, setSelectedTemplate,
      templates, saveTemplate,
      refreshTemplates,
      deleteTemplate,
      setTemplateEditorState,
      templateEditorRef, 
      renderTextContent,
      renderEditorStateContent
    }
}