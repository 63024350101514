import React, {useState, useEffect, useRef} from 'react';
// import firebase from '../../firebase'

import { Container, Row, Col, Form } from 'react-bootstrap';
import styled from 'styled-components'
import PropTypes from 'prop-types';

import SendGmailButton from './SendGmailButton'
import EmailTemplateSelector from './EmailTemplateSelector';

import GapiContextProvider from '../../../contexts/GapiClientContext'
import SendGmailSuccessMessage from './SendGmailSuccessMessage';
import SendGmailErrorMessage from './SendGmailErrorMessage';

import DraftjsEmailEditor from '../draftjsEmailEditor/DraftjsEmailEditor'

import { EditorState } from "draft-js";

function EmailMessageComposerTab(
  { 
    setError,
    renderTextContent,
    renderEditorStateContent,
    templates,
    selectedTemplate,
    setSelectedTemplate,
    selectedProfile,
    user
  }){

    const [messageEditorState, setMessageEditorState] = useState( EditorState.createEmpty() )
    const messageEditorRef = useRef(null);
  
  const [emailMessage, setEmailMessage] = useState({
        from_email: user.email || "",
        from_name: user.displayName || "",
        to: selectedProfile?.email || "",
        cc: "",
        bcc: "",
        subject: selectedTemplate?.subject || "",
        body: messageEditorState.getCurrentContent() || ""
      });

  useEffect( ()=>{
    setMessageEditorState( renderEditorStateContent(selectedTemplate.body) )
  }, [renderEditorStateContent, selectedTemplate.body])

  useEffect( () => {
    const emailMessage = {
        from_email: user.email,
        from_name: user.displayName,
        from_id: user.uid,
        to: selectedProfile?.email,
        to_id: selectedProfile?.id,
        cc: "",
        bcc: "",
        subject: renderTextContent(selectedTemplate?.subject),
        body: messageEditorRef.current.editor.innerHTML,
        uid: user.uid
      }

    setEmailMessage(emailMessage)
  }, [selectedProfile, selectedTemplate, user, messageEditorState, messageEditorRef, renderTextContent, renderEditorStateContent])

  const handleChangeField = (event) => {
    let value = event.target.value;
    let field = event.target.getAttribute('field');
    // console.info(`field ${field}, value ${value}`)
    let newMessage = Object.assign( {}, emailMessage)

    newMessage[field] = value;

    setEmailMessage(newMessage)
  }

  // const saveEmailSentStatus = async (e) => {
  //   //todo change to ref
  //   await db.collection('users').doc(user.uid)
  //     .collection('githubProfiles').doc(selectedProfile.login).set({emailSent: new Date()})
  // }

  return (
    <TemplateContainer className="sticky-top EmailMessageComposerTab">
      <Row >
        <Col md={12} >
          <Form>
            <Form.Group as={Row} controlId="formBasicEmail">
              <Form.Label column sm="2">Template:</Form.Label>
              <Col sm="10">
                <EmailTemplateSelector 
                  templates={templates}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="emailFrom">
              <Form.Label column sm="2">From: </Form.Label>
              <Col sm="10">
                <span>&quot;{user.displayName}&quot; &lt;{user.email}&gt;</span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="emailTo">
              <Form.Label column sm="2">To: </Form.Label>
              <Col sm="10">
                <Form.Control field="to" type="email-address"
                  placeholder="To Address"
                  defaultValue={emailMessage.to}
                  onChange={handleChangeField}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="emailCC">
              <Form.Label column sm="2">CC: </Form.Label>
              <Col sm="10">
                <Form.Control field="cc" type="email-address"
                  placeholder="CC"
                  value={emailMessage.cc}
                  onChange={handleChangeField}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="emailBcc">
              <Form.Label column sm="2">BCC: </Form.Label>
              <Col sm="10">
                <Form.Control field="bcc" type="email-address"
                  placeholder="BCC"
                  value={emailMessage.bcc}
                  onChange={handleChangeField}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="emailSubject">
              <Form.Label column sm="2">Subject </Form.Label>
              <Col sm="10">
                <Form.Control field="subject" type="text"
                  placeholder="Subject"
                  value={emailMessage.subject}
                  onChange={handleChangeField} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="emailBody">
              <Form.Label column sm="2">Body </Form.Label>
              <Col sm="10">
                <DraftjsEmailEditor
                  editorState={messageEditorState}
                  setEditorState={setMessageEditorState}
                  editorRef={messageEditorRef} />
              </Col>
            </Form.Group>
          </Form>
          <GapiContextProvider>
            <SendGmailButton
              emailMessage={emailMessage}
              />
              <SendGmailSuccessMessage />
              <SendGmailErrorMessage />
          </GapiContextProvider>
        </Col>
      </Row>
    </TemplateContainer>
  );
}

const TemplateContainer = styled(Container)`
  margin-top: 10px;
`

EmailMessageComposerTab.propTypes = {
  setError: PropTypes.func.isRequired,
  renderTextContent: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.object,
    updatedAt: PropTypes.object,
    subject: PropTypes.string,
    body: PropTypes.object,
    owner: PropTypes.string
  }),
  setSelectedTemplate: PropTypes.func.isRequired,
  selectedProfile: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string
  }),
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    email:PropTypes.string.isRequired,
    uid:PropTypes.string.isRequired,
    googleRefreshToken: PropTypes.string.isRequired
  })
}

export default EmailMessageComposerTab