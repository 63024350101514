import React, { useContext } from 'react';

import { 
  GapiClientContext, 
} from '../../../contexts/GapiClientContext'

const SendGmailErrorMessage = ({props}) => { // props

  const { gmailErrorMessage } = useContext(GapiClientContext)
  
  return (
    <h2 className="text-error">{gmailErrorMessage}</h2>
  )
}

export default SendGmailErrorMessage;