import React from 'react'
import { PropTypes } from 'prop-types';

import { Editor, RichUtils, getDefaultKeyBinding} from "draft-js";
import '../../../../node_modules/draft-js/dist/Draft.css';
import "./DraftjsEmailEditor.css"

import BlockStyleControls from './BlockStyleControls'
import InlineStyleControls from './InlineStyleControls'

function EmailEditor({editorState, setEditorState, editorRef}){
  const handleFocus = () => editorRef.current.focus();
  const handleChange = editorState =>  setEditorState(editorState); 

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleChange(newState);
      return true;
    }
    return false;
  }

  const mapKeyToEditorCommand = (evt) => {
    if (evt.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        evt,
        editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== editorState) {
        handleChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(evt);
  }

  const toggleBlockType = (blockType) => {
    handleChange(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    );
  }

  const toggleInlineStyle = (inlineStyle) => {
    handleChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  }


    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();

    if (!contentState?.hasText()) {
      if (contentState?.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div className="RichEditor-root">
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <div className={className} onClick={handleFocus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={handleChange}
            placeholder="Tell a story..."
            ref={editorRef}
            spellCheck={true}
          />
        </div>
      </div>
    );
  }

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

EmailEditor.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  editorRef: PropTypes.object.isRequired
}

export default EmailEditor