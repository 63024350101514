/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import EmailTemplateSelector from './EmailMessageComposerTab/EmailTemplateSelector';
import DraftjsEmailEditor from '../EmailSidebar/draftjsEmailEditor/DraftjsEmailEditor'

function EmailTemplateComposerTab(
  { 
    templateEditorRef,
    setTemplateEditorState,
    error,
    saveTemplate,
    deleteTemplate,
    templates,
    selectedTemplate,
    setSelectedTemplate,
    user,
    setTitle,
    setSubject
  }){


  const handleSaveTemplate = async event => {
    event.preventDefault();
    saveTemplate()
  }


  const handleDeleteTemplate = event => {
    event.preventDefault();
    deleteTemplate()
  }

  const onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  }

  return (

    <TemplateContainer className="sticky-top EmailTemplateMaker">
      <Row >
        <Col md={12} >
          <h1>Templatize!</h1>
          {error &&<Error>ERROR {error}</Error>}

          <Form>
            <EmailTemplateSelector 
              templates={templates}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />

            <Form.Group controlId="formTemplateTitle">
              <Form.Label>Template Title: </Form.Label>
              <Form.Control field="title" type="string" placeholder="Title" 
                value={selectedTemplate.title} 
                onChange={e => {
                  e.preventDefault(e)
                  setTitle(e.target.value)}
                } />
            </Form.Group>
            <Form.Group as={Row} controlId="formTemplateEmailFrom">
              <Form.Label column sm="2">From: </Form.Label>
              <Col sm="10">
                <span>&quot;{user.displayName}&quot; &lt;{user.email}&gt;</span>
              </Col>
            </Form.Group>
            <Form.Group controlId="formTemplateEmailSubject">
              <Form.Label>Subject </Form.Label>
              <Form.Control field="subject" type="text" placeholder="Subject" 
                value={selectedTemplate.subject} 
                onChange={ e => { 
                  e.preventDefault();
                  setSubject(e.target.value)}
                } />
            </Form.Group>
            <Form.Group controlId="formTemplateEmailBody">
              <Form.Label>Body </Form.Label>
              {/* <Form.Control field="body" as="textarea" rows="15" value={body} onChange={ e => setBody(e.target.value) } /> */}
              <DraftjsEmailEditor
                  editorState={selectedTemplate.body}
                  setEditorState={setTemplateEditorState}
                  onEditorStateChange={onEditorStateChange}
                  editorRef={templateEditorRef} />
            </Form.Group>


            <Form.Row>
              <Form.Group as={Col} md={10}>
                <Button variant="primary" onClick={handleSaveTemplate}>
                  Save
                </Button>
                </Form.Group>
              <Form.Group as={Col} md={2}>
                <Button variant="danger" type="delete" onClick={handleDeleteTemplate}>
                  Delete
                </Button>
              </Form.Group>
            </Form.Row>
            <p>You can use the following in your template:</p>
            <ul>
              <li>{'{'}name{'}'} - the user's full name</li>
              <li>{'{'}fname{'}'} - the user's first name</li>
              <li>{'{'}location{'}'} - the location keyword</li>
              <li>{'{'}keyword{'}'} - the search keyword</li>              
            </ul>
          </Form>
        </Col>
      </Row>
    </TemplateContainer>

  );
}

const TemplateContainer = styled(Container)`
  margin-top: 10px;
`

const Error = styled.h2`
  color: red;
`

EmailTemplateComposerTab.propTypes = { 
  setError: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  refreshTemplates: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    from_name: PropTypes.string,
    from_email: PropTypes.string,
    createdAt: PropTypes.object,
    updatedAt: PropTypes.object,
    subject: PropTypes.string,
    owner: PropTypes.string
  }),
  setSelectedTemplate: PropTypes.func.isRequired,
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    email:PropTypes.string.isRequired,
    uid:PropTypes.string.isRequired
  })
}

export default EmailTemplateComposerTab