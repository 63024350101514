
import React from 'react';
import { Form  } from 'react-bootstrap';
import PropTypes from 'prop-types';

function EmailTemplateSelector({ templates, selectedTemplate, setSelectedTemplate}){

  const handleChange = (e) => {
    const t = templates.find( t => e.target.value === t.id )
    setSelectedTemplate(t);
  }
  
  return (
    <Form.Group>
      <Form.Control className="templateSelect"
        value={selectedTemplate?.id}
        as="select"
        onChange={ handleChange }>

        {
          templates.map( (template) => {
             return <option id={template.id} key={template.id} 
              value={template.id}>{template.title}</option>
          })
        }
      </Form.Control>
    </Form.Group>
  );

}


EmailTemplateSelector.propTypes = { 
  templates: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    from_email: PropTypes.string,
    from_name: PropTypes.string,
    createdAt: PropTypes.object,
    updatedAt: PropTypes.object,
    subject: PropTypes.string,
    body: PropTypes.object,
    owner: PropTypes.string
  }).isRequired,
  setSelectedTemplate: PropTypes.func.isRequired
}

export default EmailTemplateSelector