import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Button } from 'react-bootstrap';
import {FaSignOutAlt} from 'react-icons/fa'
import { Link } from "react-router-dom";
import { Avatar } from '../styledComponents';

function Nav({signOutOfAll, user}){

    return (
      <Row className="nav">
        <Col md={1}><h1>GitHunter!</h1></Col>
        <Col md={8}></Col>
        <Col className="my-auto" md={2}><Button variant="light" onClick={signOutOfAll}>Sign Out <FaSignOutAlt/></Button></Col>
        <Col md={1}>
          <Link to="/settings">
            <Avatar src={user.photoURL} alt="Avatar" />
          </Link>
        </Col>
      </Row>
    )
}


Nav.propTypes = {
  signOutOfAll: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    photoURL: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: null
  })
}

export default Nav