import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import styled from 'styled-components'
import { Form, Button } from 'react-bootstrap';

import { FaEnvelope, FaPlusCircle, FaLinkedin } from 'react-icons/fa';

import { getValidUrl } from '../utils';

import { db } from '../firebase'
function ProfileItem({profile, githubToken, setSelectedProfile, user}){

  const [profileDetail, setProfileDetail] = useState({})
  const [emailInput, setEmailInput] = useState(false)
  const [emailAddress, setEmailAddress] = useState("")
  const [error, setError] = useState("")
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const Blog = () => {
    if(!profileDetail.blog){ return <span>{profileDetail.name}</span> }
    const blog = getValidUrl(profileDetail.blog)
    return <a href={blog} target="_blank" rel="noopener noreferrer" >{profileDetail.name}</a> 
  }

  const processCompany = () => {
    if(!profileDetail.company){ return { __html: ""} }

    const regex = /\B@\w+/g // match words beginning with @
    const companyData = profileDetail.company.replace(regex, 
      function(matched){
        const withoutAt = matched.replace(/@/, "")
        return `<a href="https://github.com/${withoutAt}" target="_blank">${matched}</a>`;
      });

    return { __html: `<span>${companyData}</span>` }
  }
  
  useEffect( () => {
    const fetchGithunterData = async () => {
      const githunterData = await db
        .doc(`users/${user.uid}/githubProfiles/${profile.login}`)
        .get()
        return githunterData.exists ? githunterData.data() : {};
    }

    const fetchGitData = async () => {
      const gitData = await axios.get(profile.url, 
        { headers: { 'Authorization': `token ${githubToken}` }  }
      );
      
      return gitData.data; 
    }

    (async () => {
      const gitData = await fetchGitData()
      const githunterData = await fetchGithunterData()
      
      setProfileDetail({...gitData, ...githunterData });
    })()
  },[user, profile, githubToken])

  const handleStarClick = async (e) => {
    //toggle starred
    let starred = profileDetail.starred ? false : new Date();
    let newProfileDetail = profileDetail;
    newProfileDetail.starred = starred;
    setProfileDetail(newProfileDetail)
    forceUpdate()

    try{
      await db
      .doc(`users/${user.uid}/githubProfiles/${profile.login}`)
      .set({starred: starred})
    } catch(err){
      newProfileDetail.starred = newProfileDetail.starred ? false : new Date();
      console.log("error setting profile detail", err)
      setError("error setting profile detail")
    }
} 

  const handleXclick = async (e) => {
   // console.log("handleXclick")
    let profileData = profileDetail;
    profileData.archived = new Date();
    setProfileDetail(profileData)
    forceUpdate()
    // archive user
    await db
    .doc(`users/${user.uid}/githubProfiles/${profile.login}`)
    .set({archived: new Date()})
  }

  const handleSaveEmail = async (e) => {
   // console.log("handleSaveEmail")
    let profileData = profileDetail;
    profileData.email = emailAddress;
    setProfileDetail(profileData)
    setEmailInput(false)

    await db
    .doc(`users/${user.uid}/githubProfiles/${profile.login}`)
    .set({email: emailAddress})
  }


  const LinkedIn = () => {
    let url = "https://www.linkedin.com/search/results/people/?keywords="
    url += encodeURI(profileDetail.name)

    return <a href={url} target="_blank" rel="noopener noreferrer" ><FaLinkedin /></a>
  }

 /// BEGIN RENDER

  if (profileDetail.archived || profileDetail.emailSent ){
    return null 
  }


  return (
    <tr>
      <td className="table-action">
        <Actions>
          <li className="archive-profile" 
           onClick={handleXclick} >✖</li>
           <li className="archive-profile" 
           onClick={handleStarClick} >
           {
            profileDetail.starred ? 
            <span role="img" aria-label="starred">⭐</span>
             : <span>✩</span> 
           }
           </li>
       </Actions>
      </td>
      <td>
        <a target="_blank" rel="noopener noreferrer" href={`http://github.com/${profile.login}`}>
          <img alt="user avatar" src={profile.avatar_url} height="48" width="48" />
        </a>
      </td>
      <td><Blog/> &nbsp; <LinkedIn /></td>

      { emailInput &&
        <div className="formEmailInput" >
          <Form.Group controlId="formBasicEmail">
            <Form.Control field="email" type="email"
              placeholder="Email Address"
              value={emailAddress}
              onChange={ e => setEmailAddress(e.target.value)}/>
              <LinkedIn />
          </Form.Group>
          <Button onClick={handleSaveEmail}>Save</Button>
          <h2 className="error">{error}</h2>
        </div>
      }

      <td>{
        profileDetail.email ?
        <FaEnvelope onClick = { () => { setSelectedProfile(profileDetail)} }/>
        : <FaPlusCircle onClick={ () => { setEmailInput(true) } }/>
      }
      </td>
      <td dangerouslySetInnerHTML={ processCompany() } />
      <td>{profileDetail.hireable ? "Yes": "No"}</td>
      <td>{profileDetail.location}</td>
      <td>{profileDetail.bio}</td>
      <td>{profileDetail.followers}</td>
    </tr>
  )
}

const Actions = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  cursor: pointer;
`

ProfileItem.propTypes = {
  githubToken: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    login: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    avatar_url: PropTypes.string.isRequired
  }).isRequired,
  user: PropTypes.shape({
      uid: PropTypes.string.isRequired
    }
  )
}

export default ProfileItem;