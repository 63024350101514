import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { db } from './firebase'

import { Container, Row, Col } from 'react-bootstrap';
import Nav from './components/Nav'
import Search from './components/Search'
import EmailSidebar from './components/EmailSidebar/EmailSidebar'
import Errors from './components/Errors'
import GithubAuthModal from './components/GithubAuthModal'

import { GapiClientContext } from './contexts/GapiClientContext'

function AppContainer( { signOut, authUser, user, setUser } ){

  // debugger // lets us debug the Gapi login error!

  const [errorList, setErrors] = useState([]);
  const [githubAuthModalShow, setGithubAuthModalShow] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});

  const [keyword, setKeyword] = useState( () => localStorage.getItem("keyword") || authUser?.email || "" )
  const [location, setLocation] = useState( () => localStorage.getItem("location") || "")

  const { GapiClient } = useContext(GapiClientContext)

  const signOutOfAll = () => {
    setUser(null)
    localStorage.clear();
    GapiClient.gapi.auth.setToken(null);
    GapiClient.gapi.auth.signOut();
    signOut();
  }

  useEffect( () => {
    localStorage.setItem("keyword", keyword)
  },[keyword])

  useEffect( () => {
    localStorage.setItem("location", location)
  },[location])

  useEffect( () => {
    fetchAndSetUserData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const fetchAndSetUserData = async () => {
    if(!authUser) return

    if( authUser.providerData
        .filter( provider => provider.providerId === "github.com" ).length === 0){
      setGithubAuthModalShow(true)
    }
    const doc = await db.doc(`/users/${authUser.uid}`).get()
    if (doc.exists) {
      const user = doc.data() 
      console.log("Existing user, updating it!", user);
      const idToken = await authUser.getIdToken()
      user.idToken = idToken 
      
      db.doc(`users/${user.uid}`).update(user)      
      
      return setUser(user)
    }

    console.log("New user, posting it!", authUser);
    let user = constructUser(authUser);

    await db.doc(`users/${user.uid}`).set(user)
    
    setUser(user)
  }

  const constructUser = (authUser, idToken) => {

    return {
      googleRefreshToken: authUser.refreshToken,
      lastLogin: Date.now(),
      lastLoginString: new Date( Date.now() ).toString(),
      uid: authUser.uid,
      email: authUser.email,
      photoURL: authUser.photoURL,
      displayName: authUser.displayName,
      phoneNumber: authUser.phoneNumber
    }
  } 

  const reportError = (error) => {
    setErrors(errorList.concat(error))
    setTimeout(()=>{
      setErrors([])
    }, 10000);
  }

  const clearErrors = () => setErrors([])
  const errors = {reportError, clearErrors};


  return(
      <Container fluid>
        {/*<p>{JSON.stringify(authUser, null, 4 )}</p>*/}
        {/* <Button onClick={() => setGithubAuthModalShow(true)}>Launch modal </Button>  */}
            {/* <p>{JSON.stringify(user, null, 4 )}</p> */}

        <GithubAuthModal 
          authUser={authUser} 
          setUser={setUser}
          fetchAndSetUserData={fetchAndSetUserData}
          constructUser={constructUser}
          show={githubAuthModalShow} 
          setGithubAuthModalShow={setGithubAuthModalShow} />

        { user 
        ? <Container fluid>
            <Nav 
              signOutOfAll={signOutOfAll}
              user={user} />
            <Row>
              <Errors errorList={errorList} />
            </Row>
            <Row>
              <Col md="8">
                <Search
                  user={user} 
                  errors={errors} 
                  githubToken={user.githubToken} 
                  setSelectedProfile={setSelectedProfile}
                  setKeyword={setKeyword}
                  keyword={keyword}
                  setLocation={setLocation}
                  location={location}
                  setGithubAuthModalShow={setGithubAuthModalShow}
                  />
              </Col>
              <Col md="4">
                <EmailSidebar 
                  setError={reportError}
                  clearError={clearErrors}
                  selectedProfile={selectedProfile}
                  keyword={keyword}
                  location={location}
                  user={user}
                  />
                </Col>
            </Row>
          </Container>
        :  <p>Loading...</p>
        }

      </Container>)
}

AppContainer.propTypes = {
  signOut: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    photoURL: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: null
  })
}


export default AppContainer;