export const emailRegex = /^(?:(?:[\w`~!#$%^&*-=+;:{}'|,?/]+(?:(?:.(?:"(?:\\?[\w`~!#$%^&*-=+;:{}'|,?/.()<>\\] @]|\\"|\\\\)*"|[\w`~!#$%^&*-=+;:{}'|,?/]+))*.[\w`~!#$%^&*-=+;:{}'|,?/]+)?)|(?:"(?:\\?[\w`~!#$%^&*-=+;:{}'|,?/.()<>[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d-]+(?:.[a-zA-Z\d-]+)*|\[\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}\])$/

// removes whitespace and adds http or http:// to the url
export const getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if(/^(:\/\/)/.test(newUrl)){
        return `http${newUrl}`;
    }
    if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
        return `http://${newUrl}`;
    }

    return newUrl;
};

export const getHumanReadableDate = (date) => {
    const myDate = date ?? new Date();
    return myDate.toTimeString().split(" ")[0]
}
   