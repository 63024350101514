import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

function Errors( {errorList} ) {
  return (
    <ul>
      {errorList.map((error, i) => (
        <Error key={i}>{error}</Error>
      ))}
    </ul>
  );
}

Errors.propTypes = {
  errorList: PropTypes.array.isRequired
}

const Error = styled.li`
  color:red
`

export default Errors