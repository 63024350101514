import React, { useContext } from 'react';

import { 
  GapiClientContext, 
} from '../../../contexts/GapiClientContext'

const SendGmailSuccessMessage = ({props}) => { // props

  const { gmailSuccessMessage } = useContext(GapiClientContext)
  
  return (
    <h2 className="text-success">{gmailSuccessMessage}</h2>
  )
}

export default SendGmailSuccessMessage;