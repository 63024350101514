import React from 'react';
import PropTypes from 'prop-types';

import { Container, Jumbotron, Button, Alert } from 'react-bootstrap';
import { FaGoogle } from 'react-icons/fa'


function SignInPage({signInWithGoogle, error}){

    return (
      <Container>
        <Jumbotron fluid>
          <Container>
           <h1>Login to GitHunter!</h1>
           {
            error && <Alert variant="danger">{error}</Alert>
           }
            <p>
              GitHunter helps headhunters use Github.
            </p>
            <Button onClick={signInWithGoogle}>
              <FaGoogle /> &nbsp; Login with Google
            </Button>
          </Container>
        </Jumbotron>
      </Container>
    )
}

SignInPage.propTypes = {
  signInWithGoogle: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default SignInPage