import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaGithub } from 'react-icons/fa'
import { Modal, Button, Container } from 'react-bootstrap';

import {db, firebaseAppAuth, githubProvider} from '../firebase'

function GithubAuthModal( {authUser, setUser, constructUser, errors, setGithubAuthModalShow, show} ){

  const [success, setSuccess] = useState(false);

  const callSetSuccessWithDelay = ( delay = 1000 ) => {
     setSuccess(true);
      setTimeout(()=>{
        setGithubAuthModalShow(false)
        window.location.reload();
      }, delay)
  }


  const linkGithubAccount = async () => {
    try{
      const authResult = await firebaseAppAuth.currentUser.linkWithPopup(githubProvider)
      // Accounts successfully linked.
      // console.log({authResult})
      // debugger
      await db.doc(`users/${authResult.user.uid}`).update({
        githubToken: authResult.credential.accessToken,
        githubProfile: authResult.additionalUserInfo.profile
      })

      let user = constructUser(authUser);
      user.githubToken = authResult.credential.accessToken;

      setUser(user)

      callSetSuccessWithDelay()
      // ...
    }catch(error){
      if(error.code === "auth/credential-already-in-use"){
        console.log("Sorry, this credential is in use by another account", error.credential, error.email)
        await alert(`Sorry, this credential is in use by another account: to fix this, lets try logging in with ${error.email}`)
        return
      }
      if(error.code ===  "auth/provider-already-linked"){
        debugger
        const result = await firebaseAppAuth.currentUser.unlink(githubProvider.providerId)
        console.log(result)
        await alert("Sorry, this credential has been unlinked from your account, you'll need to reauthenticate")
        window.location.reload();
      }else{
        debugger
        console.log("error", error)
      }
    }
  }

return(
   <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setGithubAuthModalShow(false)}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Github login is required
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { success
          ? <h1>Success!</h1>
          : <Container>
              <h4>Please login with Github</h4>
              <p>Github login is required so we can go crawl profiles. </p>
              <Button onClick={ () => linkGithubAccount() } variant="dark">
                <FaGithub />&nbsp; Login with Github
              </Button>
               
            </Container>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{setGithubAuthModalShow(false)}}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

GithubAuthModal.propTypes = {
  constructUser: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    photoURL: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: null
  }),
  errors: PropTypes.shape({
    reportError:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired
  })
}

export default GithubAuthModal