import React from 'react';

import StyleControlButton from './StyleControlButton';

import { 
  FaItalic,
  FaUnderline,
  FaBold,
} from 'react-icons/fa'

var INLINE_STYLES = [
  {label: <FaBold/>, style: 'BOLD'},
  {label: <FaItalic/>, style: 'ITALIC'},
  {label: <FaUnderline/>, style: 'UNDERLINE'},
];

const InlineStyleControls = ({editorState, onToggle}) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type, index) =>
        <StyleControlButton
          key={index}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

export default InlineStyleControls