// from react-app/.env 
// all urls are protocol://host:port/path   ending without a trailing slash
// import them like this: 
// import { API_HOST_URL, REACT_CLIENT_URL } from './conifg.js'

const region = "us-central1"

export const ENV = window.location.host.match("localhost") ? "dev" : "prod";
export const API_HOST_URL = process.env.REACT_APP_API_HOST_URL; 
export const REACT_CLIENT_URL = process.env.REACT_APP_REACT_CLIENT_URL;
export const APP_NAME = "githunter-7403f"
export const HOST = ENV === "dev" ? `http://localhost:5001/${APP_NAME}/${region}` : `https://${region}-${APP_NAME}}.cloudfunctions.net`;
export const MANDRILL_POST_URI = `${HOST}/mandrill`
export const GMAIL_POST_URI = `${HOST}/gmailer`

//https://www.sitepoint.com/sending-emails-gmail-javascript-api/
//https://github.com/sitepoint-editors/gmail-api-javascript-example/blob/master/02%20-%20Sending%20mail/index.html
// export const GMAIL_CLIENT_ID = '20236210343-u79nsd6pbae0u3knet4d997bcjrapirr.apps.googleusercontent.com'
// export const GMAIL_CLIENT_ID = '20236210343-vtnf0n8fu0ovqcov36srdo2hgkmg386b.apps.googleusercontent.com'
// export const GMAIL_CLIENT_ID = '20236210343-01eej7dh5qtcst0mqq4pnt4c37dnt6oj.apps.googleusercontent.com' // quickstart
// export const GMAIL_API_KEY = 'AIzaSyC8QuE-DqVmGqNglECNC8_L5BalDiCrSJk'; 
export const GMAIL_API_KEY = 'AIzaSyDdJagRhl2qFNp0XSygGYQUQbj3xpUNwqQ'; // quickstart 
export const GMAIL_CLIENT_ID = '20236210343-o6oc892ii2bshisjtfl05vt08ah0h3uk.apps.googleusercontent.com' // new githunter

export const GAPI_CONFIG = {
  'apiKey': GMAIL_API_KEY,
  'clientId': GMAIL_CLIENT_ID,
  'scope': 'https://www.googleapis.com/auth/gmail.send',
  'discoveryDocs': ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'],
  'immediate': true
}

export const firebaseConfig = {
  apiKey: "AIzaSyDSy_J8dyBNw1HWN3wqzIqbE-Fq8Z2sdTE",
  authDomain: "githunter-7403f.firebaseapp.com",
  databaseURL: "https://githunter-7403f.firebaseio.com",
  projectId: "githunter-7403f",
  storageBucket: "githunter-7403f.appspot.com",
  messagingSenderId: "20236210343",
  appId: "1:20236210343:web:429b66b16eb2baab2a0b82",
  measurementId: "G-6VHKY57ERD",
  name:"prod",
  discoveryDocs: [
    "https://gmail.googleapis.com/$discovery/rest?version=v1",
  ]
};

 export const firebaseConfigDev = {
    apiKey: "AIzaSyBfJsP_Fs1a-Nj0fni-9oPfdIfyVJkst40",
    authDomain: "githunter-dev.firebaseapp.com",
    databaseURL: "https://githunter-dev.firebaseio.com",
    projectId: "githunter-dev",
    storageBucket: "githunter-dev.appspot.com",
    messagingSenderId: "59202826322",
    appId: "1:59202826322:web:97fce15e4931c8ab7daf8b",
    name:"dev"
  };