import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);

export const firebaseAppAuth = firebase.auth();
export const githubProvider = new firebase.auth.GithubAuthProvider()
  .setCustomParameters({
    'allow_signup': 'true',
    'login': 'none'
});

export const googleProvider = new firebase.auth.GoogleAuthProvider()
  .setCustomParameters({'prompt': 'select_account'})
  .addScope('https://www.googleapis.com/auth/gmail.send')

export const db = firebase.firestore()

// exported functions
export const FirebaseTimestamp = firebase.firestore.FieldValue.serverTimestamp
export const DeleteField = firebase.firestore.FieldValue.delete

export default firebase