import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { 
  GapiClientContext, 
} from '../../../contexts/GapiClientContext'
// import { getHumanReadableDate } from '../../../utils';
// import { ENV } from '../../../config'; 

const SendGmailButton = ( { emailMessage }) => { // props
  const {
    GapiClient,
    gmailButtonText,
    setGmailIsSending,
    setGmailIsSent,
    setGmailIsError,
    setGmailErrorMessage,
    gmailIsDisabled
  } = useContext(GapiClientContext)

  const getRawDataFromEmailMessage = (emailMessage)=>{
    const mimeData = [
      `From: "${emailMessage.from_name}" <${emailMessage.from_email}>`,
      `To: ${emailMessage.to || ""}`,
      `Subject: =?utf-8?B?` + window.btoa(unescape(encodeURIComponent(emailMessage.subject))) + "?=",
      "MIME-Version: 1.0",
      "Content-Type: text/html; charset=UTF-8",
      "Content-Transfer-Encoding: 7bit",
      "",
      emailMessage.body
    ].join("\n").trim();

    return window.btoa(unescape(encodeURIComponent(mimeData))).replace(/\+/g, '-').replace(/\//g, '_');
  }

  const gmailResponseHandler = useCallback( (res) => {
    if(res && res.code){  // if there's a code it's an error
      console.log("gmail respnse", res)
      setGmailIsError(true)
      setGmailErrorMessage(`Error sending gmail: ${res.error.message}`)
      return
    }
    // once the request is sent, update state again
    setGmailIsSending(false)
    setGmailIsSent(true)
    return
  }, [setGmailErrorMessage, setGmailIsError, setGmailIsSending, setGmailIsSent])

  const gapiMessages = GapiClient?.gapi?.client?.gmail?.users?.messages ?? false
  const sendGmailHandler = useCallback(async () => {
    if(!gapiMessages){ return }
    // if(ENV === 'dev'){
    //   emailMessage.subject = 'githunter - ' + getHumanReadableDate() + ' - ' + emailMessage.subject;
    // }
    
    setGmailIsSending(true)
    
    console.log("sending gmail: ", emailMessage)
    await gapiMessages.send({
      'userId': 'me',
      'resource': {
        'raw': getRawDataFromEmailMessage(emailMessage)
      }
    }).execute(gmailResponseHandler)
    
  }, [gapiMessages, setGmailIsSending, emailMessage, gmailResponseHandler]) // update the callback if the state changes

  return (
    <>
      <Button 
        variant={ gmailIsDisabled ? "secondary": "primary" }
        onClick={sendGmailHandler} 
        disabled={gmailIsDisabled}
      >{gmailButtonText}</Button>
    </>
  )
}

SendGmailButton.propTypes = {
  emailMessage: PropTypes.shape({
    bcc: PropTypes.string.isRequired,
    body: PropTypes.isRequired,
    cc: PropTypes.string.isRequired,
    from_email:PropTypes.string.isRequired,
    from_id: PropTypes.string,
    from_name: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    to: PropTypes.string,
    to_id: PropTypes.number,
    uid: PropTypes.string
  })
}


export default SendGmailButton;