/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import styled from 'styled-components'
import PropTypes from 'prop-types';

import EmailTemplateComposerTab from './EmailTemplateComposerTab';
import EmailMessageComposerTab from './EmailMessageComposerTab/EmailMessageComposerTab';

import useEmailTemplates from './useEmailTemplates';
function EmailSidebar(
  {setError, clearError, selectedProfile = {}, keyword, location, user}
  ){
    
  const {
    useTemplatesError, 
    useTemplatesLoading,
    title, setTitle,
    subject, setSubject,
    selectedTemplate, setSelectedTemplate,
    templates, saveTemplate,
    refreshTemplates,
    deleteTemplate,
    templateEditorState, setTemplateEditorState,
    templateEditorRef,
    renderTextContent,
    renderEditorStateContent
} = useEmailTemplates(user, selectedProfile, keyword, location)    


  useEffect(() => {
    refreshTemplates();
  }, [])


  return (

    <EmailSidebarContainer className="sticky-top EmailSidebarContainer">
      <Row >
        <Col md={12} >
          <Tabs defaultActiveKey="composer" id="EmailTabsContainer">
            <Tab eventKey="composer" title="Compose">
              <EmailMessageComposerTab
                setError={setError}
                renderTextContent={renderTextContent}
                renderEditorStateContent={renderEditorStateContent}
                templates={templates}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                selectedProfile={selectedProfile}
                user={user}
                />
            </Tab>
            <Tab eventKey="templates" title="Templates">
              <EmailTemplateComposerTab 
              setError={setError}
              error={useTemplatesError}
              loading={useTemplatesLoading}
              templates={templates}
              refreshTemplates={refreshTemplates}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              title={title}
              setTitle={setTitle}
              subject={subject}
              setSubject={setSubject}
              saveTemplate={saveTemplate}
              deleteTemplate={deleteTemplate}
              user={user}
              templateEditorRef={templateEditorRef}
              templateEditorState={templateEditorState}
              setTemplateEditorState={setTemplateEditorState}
              />
            </Tab>

            {useTemplatesLoading && <Tab eventKey="temp" title="Loading..." disabled={true} >
              <></>
            </Tab>}


          </Tabs>
        </Col>
      </Row>
    </EmailSidebarContainer>

  );

}

const EmailSidebarContainer = styled(Container)`
    background-color: white;
    border-left: 1px solid lightgrey;
    height: 100%;
`

EmailSidebar.propTypes = {
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  selectedProfile: PropTypes.object,
  keyword: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    email:PropTypes.string.isRequired,
    uid:PropTypes.string.isRequired
  })
}

export default EmailSidebar;