import React, { useEffect } from 'react';

import { googleProvider, firebaseAppAuth } from './firebase'

import withFirebaseAuth from 'react-with-firebase-auth'

import './App.css';

import SignInPage from './SignInPage'
import AppContainer from './AppContainer'
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";

import GapiContextProvider from './contexts/GapiClientContext'
import Settings from './components/Settings';
import useLocalStorage from './hooks/useLocalStorage';

function App({user: authUser, signOut, signInWithGoogle, signInWithGithub, error}){
  
  const history = useHistory();
  const [user, setUser] = useLocalStorage();
  
  useEffect( ()=>{
    if(authUser || user ){
      history.push("/app")
    }else{
      history.push("/signin")
    }
  })

  return (
    <div className="App">
      <React.StrictMode>
        <Switch>
          <Route path="/app">
            <GapiContextProvider>
              <AppContainer 
                user={user}
                setUser={setUser}
                authUser={authUser}
                signInWithGithub={signInWithGithub} 
                signOut={signOut}
                />
            </GapiContextProvider>
          </Route>
          <Route path="/settings">
              <Settings 
                authUser={authUser}
                user={user}
                setUser={setUser}
                signInWithGithub={signInWithGithub} 
                signOut={signOut}
                />
          </Route>
          <Route path="/signin">
            <SignInPage 
              signInWithGoogle={signInWithGoogle}
              error={error}
              />
          </Route>
        </Switch>
      </React.StrictMode>
    </div>
  )}
  

  export default withFirebaseAuth({
    providers: {googleProvider},
    firebaseAppAuth,
  })(App);
  