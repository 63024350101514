import React, { useState, createContext, useEffect, useCallback } from 'react';

import { gapi } from 'gapi-script';
import {GAPI_CONFIG } from '../config'

export const GapiClientContext = createContext();

const GapiContextProvider = (props) => {
  
  const defaultButtonText = "Send Email"
  const sendingButtonText = "Sending"

  const [gmailSuccessMessage, setGmailSuccessMessage] = useState("");
  const [gmailErrorMessage, setGmailErrorMessage] = useState("");
  const [gmailButtonText, setGmailButtonText] = useState(defaultButtonText)
  const [gmailIsSent, setGmailIsSent] = useState(false)
  const [gmailIsSending, setGmailIsSending] = useState(false)
  const [gmailIsError, setGmailIsError] = useState(false)
  const [gmailIsDisabled, setGmailIsDisabled] = useState(true)
  
  const [GapiClient] = useState({ gapi })
  
  
  // to test initClient properly, remove access from third party access after each grant:
  // https://myaccount.google.com/permissions?continue=https%3A%2F%2Fmyaccount.google.com%2Fsecurity%3Fpli%3D1
  // then logout
  // to ensure gapi only loads once: https://stackoverflow.com/questions/68985492/how-to-prevent-script-from-loading-multiple-times-with-react
  
  const initClient = useCallback( async () => {
    if( window.gapiIsInitialized ) return 
    
    return gapi.client.init(GAPI_CONFIG)
    .then( () => {
      console.info("gapi loaded")
      window.gapiIsInitialized = true;
      setGmailIsDisabled(false)
      return
    }, (e) => {
      window.gapiIsLoading = false;
      console.info("error init gapi client", e.details)
    })
  },[])
  
  const setupGapi = useCallback(async () => {
    try{
      await gapi.load('client:auth2', initClient )
    }catch(e){
      window.gapiIsLoading = false;
      console.log("couldnt sign in to gAPI!", e)
    }
  },[initClient])

  useEffect( ()=> {
    if(window.gapiIsInitialized || window.gapiIsLoading) return 
    window.gapiIsLoading = true;
    setupGapi()
  },[initClient, setupGapi])

  useEffect( () => { // handle gmail sending state
    if(gmailIsSending){
      setGmailIsDisabled(true)
      setGmailButtonText(sendingButtonText)
    }
    if(gmailIsSent){
      setGmailSuccessMessage("Sent!")
      setGmailButtonText(defaultButtonText)
    }
    if(gmailIsError){
      setGmailIsDisabled(true)
      setGmailButtonText(defaultButtonText)
    }

    setTimeout(()=>{
      setGmailSuccessMessage([""])
      setGmailErrorMessage([""])
      setGmailIsSending(false)
      setGmailIsError(false)
      setGmailIsSent(false)
      setGmailIsDisabled(false)
    },5000)

  }, [gmailIsSending, gmailIsSent, gmailIsError])
 
  return (
    <GapiClientContext.Provider value={{
      GapiClient,
      gmailButtonText,
      gmailSuccessMessage,
      gmailErrorMessage,
      setGmailIsSending,
      setGmailIsSent,
      setGmailIsError,
      setGmailErrorMessage,
      gmailIsDisabled
    }}>
      {props.children}
    </GapiClientContext.Provider>
  );
}

export default GapiContextProvider;