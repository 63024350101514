import React from "react";

import { 
  FaCode,
  FaListUl,
  FaListOl 
} from 'react-icons/fa'

import StyleControlButton from "./StyleControlButton";

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: <FaListUl/>, style: 'unordered-list-item'},
  {label: <FaListOl/>, style: 'ordered-list-item'},
  {label: <FaCode/>, style: 'code-block'},
];

const BlockStyleControls = ({editorState, onToggle}) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type, index) =>
        <StyleControlButton
          key={index}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

export default BlockStyleControls