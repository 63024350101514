import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import styled from 'styled-components'

import { Container, Form, Button, Table } from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa'

import ProfileItem from './ProfileItem';

function Search({user, errors, githubToken, setSelectedProfile, keyword, location, setKeyword, setLocation, setGithubAuthModalShow }){

const [profiles, setProfiles] = useState([]);
const [totalCount, setTotalCount] = useState();
const [limit, setLimit] = useState( () => localStorage.getItem("limit") || 10);
const [page, setPage] = useState(() => localStorage.getItem("page") || 1)
const [invalidToken, setInvalidToken] = useState( () => !githubToken)


useEffect( () => {
  localStorage.setItem("limit", limit)
},[limit])

useEffect( () => {
  localStorage.setItem("page", page)
},[page])

const githubNotAuthenticated = () => {
  return (
    <>
      <p>github not authenticated</p>
      <p>If you've just linked your account, you may need to refresh the page, otherwise:</p>
      <Button onClick={() => setGithubAuthModalShow(true)}>Reauthenticate Github </Button> 
    </>
  )
}

const searchUsers = useCallback(async () => {
  setProfiles([])
  // console.info(`page ${page}`);

  if(!githubToken){
    console.log("no token")
    setInvalidToken(true)
    return 
  }

  let locationString = "";
  if(location !== ""){
    locationString = `+location:${location}`
  }

  const url = encodeURI(`https://api.github.com/search/users?q=${keyword}${locationString}&per_page=${limit}&page=${page}`)

  // console.info(`fetching ${url}`)
  const options = { headers: { 'Authorization': `token ${githubToken}` }  };
  // console.log("search", url, options)
  try{
    let result = await axios.get(url, options)
      setTotalCount(result.data.total_count)
      // console.log(result.data.total_count)
      setProfiles( result.data.items )
  }catch(err){
    console.log("Search caught axios err", err)
    if(err?.message.match(/401/)){
      setInvalidToken(true)
    }
  }
}, [githubToken, keyword, limit, location, page])


const handleSubmit = (e) => {
  setPage(1)
  searchUsers()
}

useEffect( ()=> { 
  //console.info(`searchUsers circluar? limit: ${limit}, page: ${page}`)
  searchUsers() 
},[limit, page, searchUsers])


if(invalidToken){
  return githubNotAuthenticated()
}

return(
  <StyledContainer id="Search">
    <ControlsForm inline onSubmit={(e) => { handleSubmit(e) } }>
      <Form.Group>
        
        <Form.Control placeholder="Keyword" type="text"
          name="keyword" value={keyword}
          onChange={e => setKeyword(e.target.value) } />
         &nbsp; &nbsp; &nbsp; 
        <Form.Control placeholder="Location" type="text"
          name="location" value={location}
          onChange={e => setLocation(e.target.value) } />
          &nbsp; &nbsp; &nbsp; 
        <Button id="submit-github-search" type="submit" value="handleSubmit"
          onClick={ (e) => {e.preventDefault(); handleSubmit()} }><FaSearch /> &nbsp; Search</Button>
          &nbsp; &nbsp; &nbsp; 
          <Form.Label>Limit</Form.Label> 
          &nbsp;
          <Form.Control 
            className="limitSelect" 
            as="select" 
            value={limit}
            onChange={ e => setLimit(e.target.value)} >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Control>
          &nbsp; &nbsp; &nbsp; 
          { totalCount && <span>results {(page-1)*limit} - {page * limit} of {totalCount} </span> }
          &nbsp; &nbsp; &nbsp; 
        { page > 1 && <Button onClick={ () => {setPage(page-1); }}>Prev</Button> }
        { page === 1 && <Button disabled>Prev</Button> }
          &nbsp; &nbsp; &nbsp; 
        <Button onClick={ () => {setPage(page+1); }}>Next</Button>

      </Form.Group>
    </ControlsForm>
        <Table id="profilesTable" responsive="xs">
      <tbody>
      <tr>
          <th className="table-action" >✖</th>
          <th>Avatar</th>
          <th>Name</th>
          <th>Email</th>
          <th>Company</th>
          <th>Hireable</th>
          <th>Location</th>
          <th>Bio</th>
          <th>Followers</th>
      </tr>
      { 
          profiles.map( (item, key) => {
            return <ProfileItem 
              profile={item}
              user={user}
              key={key}
              githubToken={githubToken}
              setSelectedProfile={setSelectedProfile} 
            />
          })

      }
      </tbody>
    </Table>
  </StyledContainer>
  )
}

Search.propTypes = {
  githubToken: PropTypes.string.isRequired,
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    photoURL: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: null
  }),
  errors: PropTypes.shape({
    reportError:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired
  }),
  setSelectedProfile: PropTypes.func.isRequired,
  setKeyword: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired
}


const StyledContainer = styled(Container)`
  margin-top:15px;
`
const ControlsForm = styled(Form)`
  margin-bottom:10px;
`

export default Search